.contact-page {
    padding: 50px;
    /* max-width: 800px; */
    margin: auto;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    margin-bottom: 5px; /* Spacing below the title */
    color: #333; /* Darker color for the title */
    font-size: 2rem; /* Larger font size for emphasis */
    font-weight: normal; /* Bold text for the title */
}

h2 {
    margin: 20px  0; /* Spacing above and below the subtitle */
    color: #555; /* Slightly lighter color for the subtitle */
    font-size: 1.5rem; /* Medium font size for subtitles */
    font-weight: bold; /* Normal weight for subtitles */
}
h6 {
    margin: 0px 0px 20px 0px;
    color: #555555d4;
    font-weight: 500;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
}

.contact-form input,
.contact-form textarea {
    padding: 12px; /* Slightly increased padding for inputs */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    max-width: 500px; /* Adjust max width for inputs */
    transition: border-color 0.3s ease; /* Smooth transition for focus */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #28a745; /* Highlight border on focus */
    outline: none; /* Remove default outline */
}

.contact-form button {
    padding: 12px; /* Slightly increased padding for the button */
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.contact-form button:hover {
    background-color: #218838; /* Darker green on hover */
}

.map-container {
    margin: 20px 0;
}

.map-container h2 {
    margin: 40px; /* Spacing below the map title */
    color: #333; /* Darker color for the title */
}

.map-container iframe {
    width: 100%; /* Full width */
    height: 450px; /* Standard height */
    border: none; /* No border for the iframe */
    border-radius: 5px; /* Rounded corners for the map */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-page {
        padding: 15px;
    }

    .contact-form {
        gap: 10px;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 0.9rem;
    }

    .contact-form button {
        font-size: 0.9rem;
    }

    .map-container iframe {
        height: 300px; /* Adjust height for mobile */
    }
}
