/* About.css */

.about-page {
    padding: 20px 20px 0px 20px;
    background-color: #f2f2f2;
}

/* Flexbox layout for the about section */
.about-section {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    font-family: 'Montserrat', sans-serif;
}

/* About Image Styles */
.about-image {
    flex: 1;
    max-width: 50%; /* Image takes up half the container */
    height: auto;
    display: flex;
    align-items: center;
}

.about-image img {
    width: 100%; /* Full width of container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
}

/* About Text Styles */
.about-text {
    flex: 1;
    padding: 0px 30px;
    color: #333;
    text-align: justify;
}

/* Heading Styles */
.about-title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #c4c4c4;
}

.about-heading {
    font-size: 45px;
    margin: 0;
    margin-bottom: 30px;
    font-weight: 100;
}

.about-description {
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Media Queries for Responsive Design */

/* For tablets and smaller screens */
@media (max-width: 1024px) {
    .about-section {
        flex-direction: column; /* Stack image and text */
        text-align: center;
    }

    .about-image {
        max-width: 100%; /* Image takes full width */
        margin-bottom: 20px; /* Add spacing between image and text */
    }

    .about-text {
        padding: 0px 20px;
    }

    .about-heading {
        font-size: 36px; /* Adjust heading size for smaller screens */
    }

    .about-description {
        font-size: 20px; /* Smaller font for the description */
    }
}

/* For mobile screens */
@media (max-width: 768px) {
    .about-heading {
        font-size: 28px;
    }

    .about-description {
        font-size: 18px;
    }

    .about-text {
        padding: 0 10px;
    }

    .about-image img {
        object-fit: contain; /* Ensure the image is fully visible */
    }
}

@media (max-width: 480px) {
    .about-heading {
        font-size: 24px;
    }

    .about-description {
        font-size: 16px;
    }

    .about-text {
        padding: 0 5px;
    }
}
