/* Category.css */
.category-page {
  padding: 20px;
}

.back-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem; /* Base font size */
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #555;
}

.category-title {
  font-size: 2rem; /* Base title font size */
  margin-bottom: 20px;
}

.menu-list {
  list-style: none;
  padding: 0;
}

.menu-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
}

.item-info {
  flex: 1;
}

.item-name {
  font-size: 1.5rem; /* Base item name font size */
  margin: 0;
}

.item-description {
  font-size: 1rem; /* Base description font size */
  color: #777;
}

.item-price {
  font-size: 1.5rem; /* Base price font size */
  color: #333;
  font-weight: bold;
}

/* Search Bar Styles */
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px; /* Fixed width */
}

.search-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #ff6347;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .back-button {
      font-size: 0.9rem; /* Decrease font size on smaller screens */
      padding: 8px 16px; /* Decrease padding */
  }

  .category-title {
      font-size: 1.8rem; /* Decrease title font size */
  }

  .item-name {
      font-size: 1.3rem; /* Decrease item name font size */
  }

  .item-description {
      font-size: 0.9rem; /* Decrease description font size */
  }

  .item-price {
      font-size: 1.3rem; /* Decrease price font size */
  }

  .search-bar input {
      width: 150px; /* Decrease search bar width */
  }
}

@media (max-width: 768px) {
  .back-button {
      font-size: 0.8rem; /* Further decrease font size */
      padding: 6px 12px; /* Further decrease padding */
  }

  .category-title {
      font-size: 1.5rem; /* Decrease title font size */
  }

  .item-name {
      font-size: 1.2rem; /* Decrease item name font size */
  }

  .item-description {
      font-size: 0.8rem; /* Decrease description font size */
  }

  .item-price {
      font-size: 1.2rem; /* Decrease price font size */
  }

  .search-bar input {
      width: 120px; /* Further decrease search bar width */
  }

  .search-button {
      padding: 8px; /* Adjust button size */
  }
}

@media (max-width: 576px) {
  .back-button {
      font-size: 0.7rem; /* Minimum font size */
      padding: 5px 10px; /* Minimum padding */
  }

  .category-title {
      font-size: 1.2rem; /* Further decrease title font size */
  }

  .item-name {
      font-size: 1rem; /* Further decrease item name font size */
  }

  .item-description {
      font-size: 0.7rem; /* Further decrease description font size */
  }

  .item-price {
      font-size: 1rem; /* Further decrease price font size */
  }

  .search-bar input {
      width: 100px; /* Minimum width */
  }

  .search-button {
      padding: 5px; /* Minimum button size */
  }
}
