.review-card {
  padding: 20px;
  text-align: center;
  margin: 0 auto; /* Center the card */
  width: 100%; /* Full width of the screen */
  max-width: 600px; /* Max width for larger screens */
  transition: transform 0.3s ease-in-out; /* Smooth effects */
}
.review-text {
  font-size: 1rem; /* Increased font size for better visibility */
  color: #333; /* Dark text color */
  text-align: center;
  margin: 20px 0px;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 1; /* Start visible */
}

.reviewer-name {
  font-size: 1.2rem;
  color: #555; /* Slightly darker color for contrast */
  margin-top: 10px;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 1; /* Start visible */
}


/* Animation for review image */
.review-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  transition: transform 0.3s ease; /* Transition for image scale */
}

/* Keyframe animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start below */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at original position */
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px); /* Start above */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at original position */
  }
}

/* Keyframe animation for pop effect */
@keyframes pop {
  0% {
    transform: scale(0.8); /* Start smaller */
  }
  50% {
    transform: scale(1.1); /* Scale up slightly */
  }
  100% {
    transform: scale(1); /* End at original size */
  }
}

/* Add these classes for the review card */
.slide-up {
  animation: slideUp 0.8s forwards; /* Animation for sliding up */
}

.slide-down {
  animation: slideDown 0.8s forwards; /* Animation for sliding down */
}

/* Apply pop effect to the image */
.pop-image {
  animation: pop 0.5s ease forwards; /* Animation for pop effect */
}

/* Main container */
.reviews-carousel {
  padding: 40px; /* Adjust padding as needed */
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  position: relative;
}

/* Reviews headline */
.reviews-headline {
  font-size: 2rem;
  color: #c4c4c4;
  margin-bottom: 40px;
}

/* Carousel navigation buttons */
.prev-button,
.next-button {
  background-color: transparent; /* Transparent background for buttons */
  font-size: 2rem; /* Adjust size */
  color: #333;
  cursor: pointer;
  padding: 10px; /* Adjust padding */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
}

/* Remove border */
.prev-button,
.next-button {
  border: none; /* No border */
}

.prev-button {
  left: 20px; /* Adjust positioning */
}

.next-button {
  right: 20px; /* Adjust positioning */
}

/* Show buttons on hover */
.reviews-carousel:hover .prev-button,
.reviews-carousel:hover .next-button {
  opacity: 1; /* Show on hover */
  transform: translateY(-50%) scale(1.1); /* Scale on hover */
}

/* Large screens (above 1024px) */
@media (min-width: 1025px) {
  .review-card {
    max-width: 600px; /* Maintain max width for large screens */
  }
}

/* Tablets (between 768px and 1024px) */
@media (max-width: 1024px) and (min-width: 768px) {
  .reviews-cards {
    justify-content: center; /* Center cards */
  }

  .review-card {
    max-width: 90%; /* Adjust max-width for tablets */
  }
}

/* Mobile screens */
@media (max-width: 768px) {


  .reviews-cards {
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
}

  .review-card {
    max-width: 100%; /* Full width for mobile */
    flex: 0 0 100%; /* Cards take up full width */
    animation: slideUp 0.8s forwards; /* Add animation for mobile */
  }

  /* Hide navigation buttons for mobile since we have swipe and auto-scroll */
  .prev-button,
  .next-button {
    display: none; /* Hiding navigation buttons for mobile */
  }
}
