.home {
  background-image: url('/public/assets/Home.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Parallax effect */
  padding: 0 20px; /* Add padding to prevent text from touching screen edges */
}

.home-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.home .mainbtn {
  text-decoration: none;
  padding: 10px 20px; /* Increase padding for better touch support */
  margin-top: 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem; /* Ensure the button text is readable */
  transition: background-color 0.3s ease;
}

.home .mainbtn:hover {
  background-color: #444; /* Add hover effect for better user feedback */
}

.title {
  padding: 0;
  margin: 0;
  font-size: 4.5rem;
  font-family: 'Sacramento', cursive;
  color: white;
  font-weight: 100;
}

.main-title {
  padding: 0;
  margin: 0;
  font-size: 5rem;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.subtitle {
  padding: 0;
  margin: 0;
  font-size: 2rem;
  font-family: 'Sacramento', cursive;
  color: white;
  font-style: italic;
  font-weight: 100;
}

/* Media Queries for Enhanced Responsiveness */

/* For larger screens (desktops) */
@media (min-width: 1200px) {
  .title {
    font-size: 5.5rem; /* Slightly larger on very large screens */
  }

  .main-title {
    font-size: 6.5rem; /* Increase size for larger screens */
  }

  .subtitle {
    font-size: 2.5rem;
  }

  .home-container {
    padding: 0 60px; /* Add more horizontal padding on larger screens */
  }
}

/* For medium-sized screens (tablets and small desktops) */
@media (min-width: 768px) and (max-width: 1199px) {
  .title {
    font-size: 4rem; /* Adjust for medium screens */
  }

  .main-title {
    font-size: 5rem;
  }

  .subtitle {
    font-size: 1.8rem;
  }

  .home-container {
    padding: 0 40px;
  }

  .home .mainbtn {
    font-size: 1.1rem; /* Reduce button text size slightly */
  }
}

/* For small screens (mobile landscape and small tablets) */
@media (min-width: 481px) and (max-width: 767px) {
  .title {
    font-size: 3.5rem;
  }

  .main-title {
    font-size: 4rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .home-container {
    padding: 0 30px;
  }

  .home .mainbtn {
    font-size: 1rem; /* Adjust button size for smaller screens */
    padding: 8px 16px; /* Make button smaller for better fit */
  }
}

/* For extra small screens (mobile phones in portrait mode) */
@media (max-width: 480px) {
  .title {
    font-size: 2.5rem;
  }

  .main-title {
    font-size: 3.2rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .home-container {
    padding: 0 20px;
  }

  .home .mainbtn {
    font-size: 0.9rem; /* Make button text smaller for very small screens */
    padding: 8px 14px;
  }
}
