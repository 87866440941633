/* Menu Page Styles */
.MenuPage .navbar {
  background-color:rgb(50 50 50 / 30%); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Adjust blur intensity */
}

/* Menu Container */
.menu-container {
  padding: 20px;
  text-align: center;
}

.menu-title {
  font-size: 2.5rem; /* Base title font size */
  margin-bottom: 20px;
  color: #333;
}

.search-bar {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.search-bar input {
  padding: 10px;
  font-size: 1rem; /* Base font size */
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 50%; /* Default width */
}

/* Category List */
.category-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px; /* Space between category cards */
}

/* Category Card */
.category-card {
  background-color: #f9f9f9;
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.category-card:hover {
  transform: translateY(-5px); /* Hover effect */
}

/* Category Icon */
.category-icon {
  font-size: 3rem; /* Base icon size */
  color: #333;
}

/* Category Name */
.category-name {
  margin-top: 10px;
  font-size: 1.2rem; /* Base category name font size */
  color: #333;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .menu-title {
    font-size: 2rem; /* Decrease title size for smaller screens */
  }

  .search-bar input {
    width: 70%; /* Increase width for smaller screens */
    font-size: 0.9rem; /* Slightly decrease font size */
  }

  .category-card {
    width: 120px; /* Decrease card width for smaller screens */
    height: 120px; /* Decrease card height for smaller screens */
  }

  .category-icon {
    font-size: 2.5rem; /* Decrease icon size */
  }

  .category-name {
    font-size: 1rem; /* Decrease category name font size */
  }
}

@media (max-width: 768px) {
  .menu-title {
    font-size: 1.5rem; /* Further decrease title size */
  }

  .search-bar input {
    width: 100%; /* Full width for mobile */
    font-size: 0.8rem; /* Further decrease font size */
  }

  .category-card {
    width: 100px; /* Further decrease card width */
    height: 100px; /* Further decrease card height */
  }

  .category-icon {
    font-size: 2rem; /* Further decrease icon size */
  }

  .category-name {
    font-size: 0.9rem; /* Further decrease category name font size */
  }
}

@media (max-width: 576px) {
  .menu-title {
    font-size: 1.2rem; /* Minimum title size */
  }

  .category-card {
    width: 80px; /* Minimum card width */
    height: 80px; /* Minimum card height */
  }

  .category-icon {
    font-size: 1.5rem; /* Minimum icon size */
  }

  .category-name {
    font-size: 0.6rem;
    margin: 0px;
}
}
