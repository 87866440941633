/* Reservation.css */

.reservation-section {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: #fff;
  background-color: rgb(107 0 100); /* Dark background for contrast */
}

.reservation-title {
  text-align: center; /* Center the title */
  margin-bottom: 20px; /* Space below the title */
  font-size: 2rem; /* Larger title */
}

.reservation-form {
  display: flex;
  flex-wrap: wrap; /* Allow form elements to wrap on smaller screens */
  gap: 10px;
  justify-content: space-between; /* Even spacing */
  align-items: center; /* Vertically align the form elements */
}

.reservation-form label {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  flex: 1;
  margin-bottom: 10px;
}

.reservation-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.submit-button {
  padding: 10px 20px; /* Button padding */
  background-color: #333; /* Button background */
  color: white; /* Button text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #ff6347; /* Change color on hover */
}

.message {
  color: green; /* Message text color */
  text-align: center; /* Center the message */
}

/* Media Queries for Responsiveness */

/* For small screens */
@media (max-width: 768px) {
  .reservation-form {
    flex-direction: column; /* Stack form fields on top of each other */
  }
}

@media (max-width: 480px) {
  .reservation-section {
    padding: 15px; /* Adjust padding for smaller devices */
  }

  .reservation-title {
    font-size: 1.5rem; /* Adjust title size for smaller screens */
  }

  .submit-button {
    width: 100%; /* Make submit button full width */
  }
}
