/* General Footer Styles */
.footer {
  background-color: #333;
  color: #fff;
  padding: 50px 20px 10px 20px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  padding: 20px;
  min-width: 250px;
}

.footer-logo {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ff6347;
}

.footer-description {
  color: #c4c4c4;
  font-size: 1rem;
  margin-bottom: 20px;
}

.footer-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ff6347;
}

.contact-info p, .twitter-feed p {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #c4c4c4;
}

.contact-info a {
  color: #fff;
  text-decoration: none;
}

.contact-info a:hover {
  color: #ff6347;
}

/* Gallery Section */
.gallery-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-images img {
  width: 80px; /* Default size */
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.gallery-images img:hover {
  transform: scale(1.1);
}

/* Footer Bottom */
.footer-bottom {
  text-align: center; /* Left-aligned copyright text */
  margin-top: 30px;
  border-top: 1px solid #444;
  padding-top: 20px;
}

.footer-bottom p {
  color: #c4c4c4;
  font-size: 1rem;
}

.myintro {
  text-align: center;
  color: #a1a1a1;
  margin: 0px;
  font-size: .8rem;
}
 .myintro a{
  font-weight: bold;
  text-decoration: underline;
  color: #a1a1a1;
}

/* Modal Styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
  object-fit: cover;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 30px;
  transition: 0.6s ease;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
}

.next {
  right: 10px;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 10px;
  border-radius: 3px 0 0 3px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start; /* Keep items left-aligned */
  }

  .gallery-images img {
    width: 80px; /* Smaller size on tablets */
    height: 80px;
  }
}

@media (max-width: 480px) {
  .footer-section {
    
    text-align: left; /* Ensure left-aligned text */
  }

  .gallery-images img {
    width: 60px; /* Smaller size on mobile */
    height: 60px;
  }

  .footer-logo {
    font-size: 1.5rem;
  }

  .footer-description {
    font-size: 0.9rem;
  }

  .footer-bottom p {
    font-size: 0.9rem;
  }
}
