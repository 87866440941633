.welcome-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh;  */
    /* min-height: 500px;  */
    background-image: url('/public/assets/coffee.png'); /* Background image */
    background-attachment: fixed; /* Parallax effect */
    background-size: cover;
    background-position: center;
    color: white; /* White text for contrast */
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
    text-align: center; /* Center-align text */
    z-index: 1; /* Ensure the text stays on top */
    overflow: hidden; /* Prevent overflow */
    margin: 10px 0px;
}

.welcome-text {
    z-index: 2; /* Place text above the background */
    background-color: rgba(0, 0, 0, 0.6); /* Dark transparent background behind text for readability */
    padding: 20px;
    border-radius: 10px;
    /* max-width: 90%; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headline {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 20px;
    color: #f0dab1; /* Light color for headline */
    font-family: 'Sacramento', cursive;
}

.description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #fff;
    text-align: justify;
    margin-bottom: 20px;
}

/* Adjust media queries for better responsiveness */
@media (max-width: 1024px) {
    .welcome-section {
        background-attachment: scroll; /* Remove parallax effect for smaller screens */
        height: auto; /* Let the height adjust based on content */
    }

    .headline {
        font-size: 2.5rem;
    }

    .description {
        font-size: 1.1rem;
    }
}

@media (max-width: 768px) {
    .welcome-section {
        padding: 20px;
        height: auto;
    }

    .headline {
        font-size: 2rem;
    }

    .description {
        font-size: 1rem;
    }

    .welcome-text {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .welcome-section {
        padding: 10px;
        height: auto;
    }

    .headline {
        font-size: 1.8rem;
    }

    .description {
        font-size: 0.9rem;
    }

    .welcome-text {
        padding: 10px;
    }
}
