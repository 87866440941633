/* Owner Message Section */
.owner-message-section {
    padding: 20px 20px 0px 20px;
    background-color: #f9f9f9;
  }
  

  

  /* Container for Image and Message */
  .owner-content {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    font-family: 'Montserrat', sans-serif;
}

  
  /* Image Styling */
  .owner-image-container {
    flex: 1 1;
    max-width: 600px;
    height: 600px;
    display: flex;
}
  
  .owner-image-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Message Styling */
  .owner-message-container {
    flex: 1;
    padding: 0px 30px;
    color: #333;
    text-align: justify;
  }
  .owner-message-headline {
    font-size: 20px;
    margin-bottom: 20px;
    color: #c4c4c4;
  
  }
  .owner-message-title {
    font-size: 45px;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 100;
}
  
.owner-message-text {
  font-size: 1.1rem;
  line-height: 1.6;
  /* margin-bottom: 20px; */
  margin: 5px;
}
  
.owner-signature {
  font-size: 1rem;
  font-style: italic;
  color: #777;
  font-family: 'Sacramento';
}
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .owner-content {
      flex-direction: column;
     text-align: center;
    }
  
    .owner-image-container {
      max-width: 100%;
      margin-bottom: 30px; /* Add space between image and text on smaller screens */
    }
  
    .owner-message-container{
      padding: 0px 20px;
    }
    .owner-message-title {
      font-size: 36px; 
    }
  
    .owner-message-text {
      font-size: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .owner-message-title {
      font-size: 28px;
    }
  
    .owner-message-text {
      font-size: 18px;
    }
  
    .owner-image-container img {
      object-fit: contain;
    }
  }
  
  @media (max-width: 480px) {
    .owner-message-title{
        font-size: 24px;
    }

    .owner-message-text {
        font-size: 16px;
    }

    .owner-message-container{
        padding: 0 5px;
    }
}