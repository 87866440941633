/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Sacramento&display=swap');

/* Logo Styles */
.logo {
  color: white;
  text-decoration: none;
}

.main-logo {
  font-size: 2rem;
  font-family: 'Playfair Display', serif; /* Custom font for The Mocktails */
}

.sub-logo {
  font-size: 1.2rem;
  font-family: 'Sacramento', cursive; /* Cursive font for Cafe and Fine Dine */
  display: block;
  color: #ffffff; /* Slightly different color for differentiation */
  margin:0px;
  text-align: center;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: transparent;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #ff6347;
}

/* Media Queries for Responsive Design */

/* For tablets and smaller screens */
@media (max-width: 1024px) {
  .nav-links {
    gap: 15px;
  }

  .nav-links a {
    font-size: 1rem;
  }

  .navbar {
    padding: 15px;
  }

  .main-logo {
    font-size: 1.8rem;
  }

  .sub-logo {
    font-size: 1rem;
  }
}

/* For mobile screens */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .navbar {
    justify-content: space-between;
  }

  .main-logo {
    font-size: 1.5rem;
  }

  .sub-logo {
    font-size: 0.9rem;
  }
}

/* For very small screens (phones) */
@media (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .main-logo {
    font-size: 1.2rem;
  }

  .sub-logo {
    font-size: 0.8rem;
  }
}
